<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <button
                class="btn btn-sm btn-primary ml-2"
                v-if="user.role.name.toLowerCase() == 'admin'"
                @click="addModal()"
              >
                <CIcon name="cilMedicalCross" />
                Tambah
              </button>

              <button
                class="btn btn-sm btn-primary ml-2"
                v-if="user.role.name.toLowerCase() == 'admin'"
                @click="addModalImport()"
              >
                <CIcon name="cilArrowThickToBottom" />
                Import
              </button>
             
              <label class="m-1 ml-3" for="">Select All : </label>
              <input
                type="checkbox"
                v-model="isSelectedAll"
                @change="checkAll()"
              />

              <select v-if="selectedItems.length > 0"
                  style="max-width: 200px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                  v-model="selectedAction"
                  @change="changeActionSelected()"
                >
                <option value="0">Action Selected</option>
                <option value="1">Delete Items Selected</option>
                <option value="2">Export Excel Items Selected</option>
                <option value="3">Export Pdf Items Selected</option>
              </select>

            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>

              <select
                  style="max-width: 200px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                  v-model="params.kecamatan"
                  @change="getData()"
                >
                <option value="">Semua Kecamatan</option>
                <option v-for="kecamatan in kecamatans" :value="kecamatan.name" :key="kecamatan.name">{{ kecamatan.name }}</option>
              </select>

            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData()"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{searchOn}}&nbsp;&nbsp;
                <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
              </span>
            </h3>
          </div>
        </div>
        <!-- <div class="mb-2">
          <b>Info Status : </b>
          <span style="color:#4baf48">&#9632;</span> Lahan Dipertahankan,
          <span style="color:red">&#9632;</span> Lahan Tidak Dipertahankan,
          <span style="color:yellow">&#9632;</span> Lahan Cadangan
        </div> -->
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="fields"
          sorter
        >
        <template #select="{ item }">
          <td class="py-2">
            <input 
                type="checkbox"
                @change="check(item)"
                v-model="item.select"
              />
          </td>
        </template>
        <!-- <template #stts_lhn="{ item }">
          <td class="py-2" style="text-align:center;">
            <span v-if="item.stts_lhn == 'Lahan Dipertahankan'" style="color:#4baf48">&#9632;</span> 
            <span v-if="item.stts_lhn == 'Lahan Tidak Dipertahankan'" style="color:red">&#9632;</span>
            <span v-if="item.stts_lhn == 'Lahan Cadangan'" style="color:yellow">&#9632;</span>
          </td>
        </template> -->
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              <CButton @click="hapus(item)" color="danger" square size="sm">
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="100"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="
        isUpdate ? 'Edit Lp2b' : 'Tambah Lp2b'
      "
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <!-- <div class="col">
          <CInput
            v-model="form.fid"
            label="FID"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.objectid"
            label="OBJECTID"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.nama_kabup"
            label="Kabupaten"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.nama_prov"
            label="Provinsi"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.id_poktan"
            label="ID Poktan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.nma_poktan"
            label="Nama Poktan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.ka_poktan"
            label="KA. Poktan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.nik_ka_pok"
            label="NIK. KA. Poktan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.alamat_ka"
            label="Alamat KA"
            placeholder="ketik disini"
          />
        </div> -->
        
        <div class="col">
          <!-- <CInput
            v-model="form.kndisi_lhn"
            label="Kondisi Lahan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.pol_tnm"
            label="Pola Tanam"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.jns_pngair"
            label="Jenis Pengairan"
            placeholder="ketik disini"
          /> -->
          <label for="">Kecamatan</label>
          <select
            name=""
            v-model="form.kecamatan"
            class="form-control"
            placeholder="Pilih"
            id=""
          >
            <option v-for="kecamatan in kecamatans" :value="kecamatan.name" :key="kecamatan.name">{{ kecamatan.name }}</option>
          </select>
          <br />
          <label for="">Kelurahan</label>
          <select
            name=""
            v-model="form.desa"
            class="form-control"
            placeholder="Pilih"
            id=""
          >
            <option v-for="kelurahan in kelurahan" :value="kelurahan" :key="kelurahan">{{ kelurahan }}</option>
          </select>
          <br />
          <CInput
            v-model="form.luas_ha"
            label="Luas Ha"
            placeholder="ketik disini"
          />
          <CInput
            type="number"
            v-model="form.id_poktan"
            label="Tahun"
            placeholder="ketik disini"
          />
          <!-- <br />
          <label for="">Status Lahan</label>
          <select
              name=""
              v-model="form.stts_lhn"
              class="form-control"
              placeholder="Pilih"
              id=""
            >
              <option value="Lahan Dipertahankan">Lahan Dipertahankan</option>
              <option value="Lahan Tidak Dipertahankan">Lahan Tidak Dipertahankan</option>
              <option value="Lahan Cadangan">Lahan Cadangan</option>
          </select> 
          <br />
          <CInput
            v-model="form.shape_leng"
            label="Shape Leng"
            placeholder="ketik disini"
          />
           <CInput
            v-model="form.shape_area"
            label="Shape Area"
            placeholder="ketik disini"
          /> -->
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="cancel" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Lp2b
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Lp2b
          </button>
        </div>
      </template>
    </CModal>
    <CModal
      size="lg"
      title="Import Data"
      centered
      color="primary"
      :show.sync="createModalImport"
    >
        <a href="lp2b.xlsx" download class="btn btn-primary">Download format</a>
        <br /><br />
        <input
            type="file"
            class="form-control"
            ref="uploadFieldBefore"
            @change="selectFileImport"
          />
        <template slot="footer">
        <div>
          <button @click="cancelImport" class="btn btn-secondary mr-3">Batal</button>

          <button @click="importData" class="btn btn-primary">
            Proses Import
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/lp2b";
import { uploadImage } from "@/utils/fileUpload";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      file: null,
      createModal: false,
      createModalImport: false,
      fields: [],
      isUpdate: false,
      items: [],
      kecamatans: [],
      kelurahan: [
      'Campaka',
      'Maleber',
      'Garuda',
      'Dungus Cariang',
      'Ciroyom',
      'Kebon Jeruk',
      'Antapani Wetan',
      'Antapani Kidul',
      'Antapani Kulon',
      'Antapani Tengah',
      'Cisaranten Bina Harapan',
      'Cisaranten Endah',
      'Cisaranten Kulon',
      'Sukamiskin',
      'Cirangrang',
      'Margasuka',
      'Margahayu Utara',
      'Babakan Ciparay',
      'Sukahaji',
      'Babakan  ',
      'Kujangsari',
      'Batununggal',
      'Mengger',
      'Wates',
      'Caringin',
      'Cigondewah Kaler',
      'Cigondewah Kidul',
      'Cigondewah Rahayu',
      'Gempolsari',
      'Cijerah',
      'Cibuntu',
      'Warung Muncang',
      'Binong',
      'Kacapiring',
      'Kebonwaru',
      'Samoja',
      'Cibangkong',
      'Maleer',
      'Kebon Gedang',
      'Cibaduyut',
      'Cibaduyut Kidul',
      'Cibaduyut Wetan',
      'Mekarwangi',
      'Kebonlega',
      'Situsaer',
      'Jatisari',
      'Sekejati',
      'Margasari',
      'Cijawura',
      'Neglasari',
      'Cigadung',
      'Cihaurgeulis',
      'Sukaluyu',
      'Cipadung',
      'Cisurupan',
      'Palasari',
      'Pasirbiru',
      'Sukamulya',
      'Pakemitan',
      'Cisaranten Wetan',
      'Babakan Penghulu',
      'Cimincrang',
      'Cisaranten Kidul',
      'Rancabolang',
      'Rancanumpang',
      'Sukapura',
      'Babakan Surabaya',
      'Babakan Sari',
      'Cicaheum',
      'Kebon Jayanti',
      'Kebon Kangkung',
      'Jatihandap',
      'Karang Pamulang',
      'Pasir Impun',
      'Sindangjaya',
      'Cipadung Kulon',
      'Cipadung Wetan',
      'Cipadung Kidul',
      'Mekarmulya',
      'Manjahlega',
      'Cipamokolan',
      'Derwati',
      'Mekarjaya',
      'Pasirluyu',
      'Ancol',
      'Ciseureuh',
      'Cigereleng',
      'Ciateul',
      'Pungkur',
      'Balonggede',
      'Cigending',
      'Pasanggrahan',
      'Pasirendah',
      'Pasirjati',
      'Pasirwangi'
      ],
      imageListAfter: [],
      imageListProcess: [],
      imageListBefore: [],
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      page: 1,
      total: 0,
      to: 0,
      form: {
   
      },
      user: JSON.parse(localStorage.getItem("user")),
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 100,
        page: 1,
        type: [0, 1],
        keyword: "",
        kecamatan: ""
      },
      isSearching: false,
      searchOn: ''
    };
  },
  methods: {
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true;
        this.getData();
        this.searchOn = this.params.keyword;
        // this.params.keyword = '';
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!");
      }
    },

    searchOff(){
      this.isSearching = false;
      this.params.keyword = '';
      this.getData();
    },
    submit() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("lp2b/addLp2b", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan data");
          loading.hide();
          this.createModal = false;
          this.form = {
          };
          this.getData();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    edit(item) {
      this.form = item;
      this.isUpdate = true;
      this.createModal = true;
      this.getKecamatan();
    },
    cancel() {
      this.form = {
        type: 0,
      };
      this.createModal = false;
    },
    update() {
      var loading = this.$loading.show();
      delete this.form.updated_at;
      this.$store
        .dispatch("lp2b/updateLp2b", {
          id: this.form.id,
          data: this.form,
        })
        .then(() => {
          this.$toast.success("Berhasil merubah data ");
          loading.hide();
          this.createModal = false;
          this.form = {
            type: 0,
          };
          this.getData();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("lp2b/deleteLp2b", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data ");

            this.form = {
              type: 0,
            };
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      }
    },
    getData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("lp2b/getLp2b", this.params)
        .then((resp) => {
          this.items = resp.data.data;
          this.total = resp.data.total;
          this.to = resp.data.to;

          // khusus untuk checkbox
          this.selectedItems = [];
          this.items.forEach(element => {
            if (this.isSelectedAll) {
              element.select = true;
              this.selectedItems.push(element.id);
            } else {
              element.select = false;
            }
          });

          this.getKecamatan();
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    addModal() {
      this.isUpdate = false;
      this.createModal = true;
      this.getKecamatan();
    },
    addModalImport() {
      this.createModalImport = true;
    },
    pagination(page) {
      this.page = page;
      this.params.page = page;
      this.getData();
    },
    uploadAfter() {
      this.$refs.uploadFieldAfter.click();
    },
    selectFileAfter(event) {
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.imageListAfter.push(resp);
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    uploadProcess() {
      this.$refs.uploadFieldProcess.click();
    },
    selectFileProcess(event) {
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.imageListProcess.push(resp);
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    uploadBefore() {
      this.$refs.uploadFieldBefore.click();
    },
    selectFileBefore(event) {
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.imageListBefore.push(resp);
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    check(item) {
      if (item.select) {
        this.selectedItems.push(item.id);
      } else {
        const index = this.selectedItems.indexOf(item.id);
        this.selectedItems.splice(index, 1);
      }
    },
    checkAll() {
      this.getData();
    },
    changeActionSelected() {
      switch (Number(this.selectedAction)) {
        case 1:
          console.log('deleted')
          this.deleteSelected('delete');
          break;
        case 2:
          console.log('export excel')
          this.exportExcel('export_excel');
          break;
        case 3:
          console.log('export pdf')
          this.exportPDF();
          break;
      }
    },
    deleteSelected(action) {
      var loading = this.$loading.show();
      this.$store
        .dispatch("lp2b/selectedAction", 
        {
          action: action,
          data: this.selectedItems,
        })
        .then((resp) => {
          this.$toast.success("Item Selected Berhasil diproses");
          loading.hide();
          this.createModal = false;
          this.form = {
          };
          this.getData();
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    openModalExcel() {
      this.exportModal = true;
      this.exportType = "Export Excel";
    },
    openModalPDF() {
      this.exportModal = true;
      this.exportType = "Export PDF";
    },
    exportExcel(action) {
      var loading = this.$loading.show();
      this.$store
       .dispatch("lp2b/exportReport", {
          data: this.selectedItems,
        })
        .then((resp) => {
          loading.hide();
          FileSaver.saveAs(
            resp.data,
            "Lp2b"
          );
          this.exportModal = false;
          this.exportDataParams = {};
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    exportPDF() {
      this.$router.push({ name: "Lp2bExportPDF", query: { data: this.selectedItems } });
    },
    getKecamatan() {
      this.$store
        .dispatch("lp2b/getKecamatan", this.params)
        .then((resp) => {
          this.kecamatans = resp.data;
          console.log(this.kecamatans, "kecamatan");
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
        });
    },
    selectFileImport(event) {
      this.file = event.target.files[0];
    },
    cancelImport() {
      this.file = null;
    },
    importData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("lp2b/importLp2b", this.file)
        .then((res) => {
          this.$toast.success(res.data.message);
          loading.hide();
          this.createModalImport = false;
          this.form = {
          };
          this.getData();
        })
        .catch((e) => {
          console.log(e)
          this.$toast.error(e);
          loading.hide();
        });
    }
  },
  computed: {
    computedItems() {  
      return this.items.map((item, index) => {
        return {
          index: (this.to - this.items.length) + index+1 + '.',
          ...item,
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        };
      });
    },
  },
  mounted() {
    this.getData();
  },

  created() {
    this.fields =
      this.user.role.name.toLowerCase() == "admin"
        ? data.fields
        : data.fields_no_action;
  },
};
</script>